import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const RecentProjectsPage = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

export const RecentProjectsWorkspace = styled(Box)(({ theme }) => ({
    height: 'calc(100% - 107px)',
    marginTop: theme.spacing(1),
    overflow: 'auto',
}));

export const RecentProjectsLoadingState = styled('span')({
    fontSize: 10,
});
