import { DialogButton } from 'components/atoms/Button';
import { IButtonWithDialog, MenuElementType } from 'components/molecules/VerticalMenu/VerticalMenu';
import appConfig from 'constants/appConfig';
import useLocalStorage from 'hooks/useLocalStorage';
import AddNewIcon from 'images/cmdAdd16.svg?react';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useCollaborationSpacePermission } from 'providers/permissions';
import { useState } from 'react';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { CollaborationSpacePermissions } from 'types/permissions';
import Checkbox from 'components/atoms/Checkbox';
import { CheckBoxLabel, CheckboxRow, Paragraph } from './useStartDesigningButton.styles';
import Box from 'components/atoms/Box';

const CONNECT_3RD_PARTY_CONSENT = 'CONNECT_3RD_PARTY_CONSENT';

export const useStartDesigningButton = (id: string) => {
    const { collaborationSpaceId } = useUserDataContext();
    const { hasCollaborationSpacePermission } =
        useCollaborationSpacePermission(collaborationSpaceId);
    const { data: featureFlagsData } = useFeatureFlagsQuery();

    const [connect3rdPartyConsent, setConnect3rdPartyConsent] = useLocalStorage<boolean>(
        CONNECT_3RD_PARTY_CONSENT,
        false
    );

    const [isPermissionRequestDialogOpen, setIsPermissionRequestDialogOpen] =
        useState<boolean>(false);
    const [isDoNotShowChecked, setDoNotShowChecked] = useState<boolean>(false);

    const button: IButtonWithDialog = {
        elementType: MenuElementType.BUTTONWITHDIALOG,
        id: id,
        leftIcon: AddNewIcon,
        children: 'Start Designing Now',
        color: 'gray',
        variant: 'text',
        onClick: () => {
            if (!connect3rdPartyConsent) {
                setIsPermissionRequestDialogOpen(true);
            } else {
                window.open(appConfig.CELUS_URL, '_blank', 'noopener,noreferrer');
            }
        },
        visible:
            featureFlagsData?.Celus_Integration &&
            hasCollaborationSpacePermission(
                CollaborationSpacePermissions.CollaborationSpaceProjectCreate
            ),
        dialogProps: {
            title: 'Permission requested',
            open: isPermissionRequestDialogOpen,
            setOpen: (open) => {
                setIsPermissionRequestDialogOpen(open);
                setDoNotShowChecked(false);
            },
            contentCssOverride: {
                maxWidth: 640,
                minWidth: 640,
            },
            buttons: (
                <>
                    <DialogButton
                        id={'buttonCancel'}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                            setIsPermissionRequestDialogOpen(false);
                            setDoNotShowChecked(false);
                        }}
                        data-testid={'confirmationDialogCancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        id={'buttonOK'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            setIsPermissionRequestDialogOpen(false);
                            setConnect3rdPartyConsent(isDoNotShowChecked);
                            window.open(appConfig.CELUS_URL, '_blank', 'noopener,noreferrer');
                        }}
                        data-testid={'confirmationDialogConfirmButton'}
                    >
                        Authorize
                    </DialogButton>
                </>
            ),
            children: (
                <Box>
                    <Paragraph css={{ fontWeight: 'bolder' }}>
                        You are about to leave Connect and enter a 3rd party application.
                    </Paragraph>
                    <Paragraph>
                        Celus is requesting permission to sign you in and read your profile
                        information. By authorizing access you agree to allow Celus to access your
                        information as specified in their terms of use and privacy policy.
                    </Paragraph>
                    <Paragraph>
                        <a href={appConfig.CELUS_TERMS_OF_USE} target={'_blank'}>
                            CELUS Design Platform Terms of Use
                        </a>{' '}
                        <a href={appConfig.CELUS_PRIVACY_POLICY} target={'_blank'}>
                            Privacy Policy
                        </a>
                    </Paragraph>
                    <CheckboxRow>
                        <Checkbox
                            checked={isDoNotShowChecked}
                            onCheckedChange={() => setDoNotShowChecked(!isDoNotShowChecked)}
                            children={<CheckBoxLabel>Do not show me again</CheckBoxLabel>}
                        />
                    </CheckboxRow>
                </Box>
            ),
        },
    };

    return {
        button,
    };
};
