import ErrorPage from 'components/templates/ErrorPage';
import ErrorLabel from 'images/error_403.svg?react';

const StaticError403 = () => {
    return (
        <ErrorPage errorLabel={<ErrorLabel />}>
            <h1>Sorry, you don't have permission to this resource.</h1>
            <p>
                Make sure it's not a link typo or contact your link sender to make sure you've been
                granted access to this resource.
            </p>
        </ErrorPage>
    );
};

export default StaticError403;
