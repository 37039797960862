import { Tab } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import ProgressSpiner from 'components/atoms/ProgressSpiner';
import { StyledTabs } from 'components/atoms/StyledTabs';
import MainLayout from 'components/templates/MainLayout';
import ProjectTiles from 'components/templates/ProjectTiles';
import ProjectsTable from 'components/templates/ProjectsTable';
import { useSettings } from 'hooks/useSettings';
import NoProjectsIcon from 'images/cmdAbortTask24.svg?react';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { useGetRecentProjectsQuery } from 'services/queries/project.query';
import { Path } from 'types/paths.enum';
import { IProjectOpen, Project } from 'types/project';
import { SelectedProjectData } from 'types/selection';

import { RecentProjectsPage, RecentProjectsWorkspace } from './RecentProjects.styles';
import { RecentProjectsMenu } from './components/RecentProjectsMenu';

export function RecentProjects() {
    const { getSetting, storeSetting } = useSettings();
    const checkViewType = (type?: string) => (type === 'table' ? 'table' : 'grid');
    const [currentViewType, setCurrentViewType] = useState(
        checkViewType(getSetting('tileView') ?? 'grid')
    );
    const [selected, setSelected] = useState({} as Project);

    const navigator = useNavigate();

    const { collaborationSpaceId } = useUserDataContext();

    const { isLoading: isLoadingProjects, data = [] } = useGetRecentProjectsQuery({
        collaborationSpaceId,
    });

    const onSelected = (selectedProjects: Array<SelectedProjectData>) => {
        const currentSelection = selectedProjects?.[0]?.original ?? {};
        setSelected(currentSelection);
    };

    const setViewType = (viewType: string) => {
        setCurrentViewType(checkViewType(viewType));
        storeSetting('tileView', viewType);
    };

    const onOpen = (project: IProjectOpen) => {
        const lastRevision = project.latestRevision ? '/' + project.latestRevision : '/0';

        currentViewType === 'grid'
            ? triggerAnalyticsClickEvent(
                  analyticsConsts.Actions.openProject,
                  analyticsConsts.Categories.navigation,
                  analyticsConsts.Sources.projectTile
              )
            : triggerAnalyticsClickEvent(
                  analyticsConsts.Actions.openProject,
                  analyticsConsts.Categories.navigation,
                  analyticsConsts.Sources.projectList
              );

        navigator(`${Path.PROJECTS}/${project.projectId + lastRevision}`);
    };

    const parsedData = useMemo(
        () =>
            data.map((x: Project) => {
                return { ...x, id: x.projectId };
            }) ?? [],
        [data]
    );

    return (
        <MainLayout>
            <RecentProjectsPage>
                <StyledTabs value={0}>
                    <Tab
                        label='Recent projects'
                        disabled={true}
                        sx={{ color: '#0f789b !important' }}
                    />
                </StyledTabs>
                <RecentProjectsMenu viewType={currentViewType} viewTypeChange={setViewType} />
                {isLoadingProjects && <ProgressSpiner label={'Loading projects. Please wait...'} />}
                <RecentProjectsWorkspace>
                    {!isLoadingProjects &&
                        data &&
                        parsedData.length !== 0 &&
                        (currentViewType === 'grid' ? (
                            <ProjectTiles
                                data={parsedData}
                                onOpen={onOpen}
                                onSelected={onSelected}
                                currentSelection={selected}
                            />
                        ) : (
                            <ProjectsTable data={data} onOpen={onOpen} onSelected={onSelected} />
                        ))}
                    {!isLoadingProjects && data && parsedData.length === 0 && (
                        <CcdEmptyState
                            id={'RecentProjectsEmptyState'}
                            message='There are no projects here yet'
                            subMessage='Create a new project first or have one shared with you'
                            icon={NoProjectsIcon}
                        />
                    )}
                </RecentProjectsWorkspace>
            </RecentProjectsPage>
        </MainLayout>
    );
}

export default RecentProjects;
