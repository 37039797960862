import styled from '@emotion/styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import { DialogButton } from 'components/atoms/Button';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import Dialog from 'components/molecules/Dialog';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { deleteProject } from 'services/api/project.service';
import { PROJECTS_QUERY } from 'services/queries/project.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { Project } from 'types/project';

const DialogContent = styled('span')({
    overflowWrap: 'break-word',
    fontSize: 12,
});

export const useProjectDelete = (project: Project) => {
    const [inProgress, setInProgress] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();

    interface LockData extends AxiosError {
        isCallerUserLock: boolean;
        lockedBy: string;
    }

    const processErrorResponse = (status: AxiosError) => {
        const lockData = status.response?.data as LockData;
        if (lockData && status.response?.status == 409) {
            if (lockData?.isCallerUserLock === false) {
                return `Project locked by ${lockData.lockedBy} and cannot be deleted.`;
            } else if (lockData?.isCallerUserLock === true) {
                return 'Failed to delete project.';
            }
        }
        return 'Failed to delete project.';
    };

    const toastCommonParams = {
        autoClose: true,
        autoCloseTime: 3000,
        onClose(): void {},
        title: '',
    };
    const { mutate } = useMutation(deleteProject, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.deleteProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectList
            );
            showToast({
                ...toastCommonParams,
                type: 'info',
                messages: ['Project deleted successfully.'],
            });
        },
        onError: (e: AxiosError) => {
            const message = [];
            message.push(processErrorResponse(e));
            showToast({
                ...toastCommonParams,
                type: 'error',
                messages: message,
            });
        },
        onSettled: () => {
            setProgressBar(false);
            setShowDialog(false);
            setInProgress(false);
        },
    });

    const DialogComponent = () => (
        <Dialog
            title={'Delete project'}
            open={showDialog}
            closeButton={!inProgress}
            setOpen={inProgress ? () => {} : setShowDialog}
            contentCssOverride={{ maxWidth: '420px' }}
            buttons={
                !inProgress ? (
                    <>
                        <DialogButton
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => setShowDialog(false)}
                            id={'deleteProjectCancelButton'}
                        >
                            Cancel
                        </DialogButton>
                        <DialogButton
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                setProgressBar(true);
                                setInProgress(true);
                                mutate(project);
                            }}
                            id={'deleteProjectDeleteButton'}
                        >
                            Delete
                        </DialogButton>
                    </>
                ) : null
            }
        >
            <DialogContent>
                {!inProgress ? (
                    <span>
                        Do you want to delete: <b>{project.name}</b>?
                    </span>
                ) : (
                    <Box>
                        <ProgressSpinner label={LoadingStateLabel.DELETING} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

    return {
        openDeleteProjectDialog: () => setShowDialog(true),
        deleteProjectDialog: <DialogComponent />,
        inProgress,
    };
};
