import { useEffect } from 'react';

import StaticPage from 'components/templates/StaticPage';
import { redirectToLogin } from 'providers/authorization/AuthProvider';

const StaticLoggedOut = () => {
    useEffect(() => {
        const timer = setTimeout(() => {
            redirectToLogin();
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <StaticPage>
            <h1>You are logged out</h1>
        </StaticPage>
    );
};

export default StaticLoggedOut;
