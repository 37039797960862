import { Tab } from '@mui/material';
import React, { useState } from 'react';

import { StyledTabs } from 'components/atoms/StyledTabs';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';

export function useAllProjectTabs() {
    const [selectedTabId, setSelectedTabId] = useState(0);

    function projectsTabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const AllProjectTabs = () => (
        <StyledTabs
            value={selectedTabId}
            onChange={(e, value) => {
                triggerAnalyticsClickEvent(
                    e.currentTarget.textContent,
                    analyticsConsts.Categories.navigation,
                    analyticsConsts.Sources.projects
                );
                setSelectedTabId(value);
            }}
            aria-label='Click the tab!'
        >
            <Tab label='All Projects' {...projectsTabProps(0)} />
            <Tab label='My Projects' {...projectsTabProps(1)} />
            <Tab label='Shared With Me' {...projectsTabProps(2)} />
            <Tab label='Shared By Me' {...projectsTabProps(3)} />
        </StyledTabs>
    );

    return { AllProjectTabs, selectedTabId };
}
