import AuthenticatedProviders from 'AuthenticatedProviders';
import { useEffect } from 'react';

import StaticLoggingIn from 'pages/StaticLoggingIn';
import { useAuthContext } from 'providers/authorization/AuthProvider';

interface Props {
    to: JSX.Element;
    title?: string;
}

const PrivateRoute = ({ to, title }: Props) => {
    const { isAuthenticated } = useAuthContext();
    useEffect(() => {
        document.title = title ? `Connect - ${title}` : 'Connect';
    }, [title]);

    return isAuthenticated ? (
        <AuthenticatedProviders>{to}</AuthenticatedProviders>
    ) : (
        <StaticLoggingIn />
    );
};

export default PrivateRoute;
