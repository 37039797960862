import { useEffect } from 'react';

import dayJs from 'shared/timeLib';

export function useLocalStorageCleanup(expiryDays = 180) {
    useEffect(() => {
        const currentDate = dayJs();
        Object.keys(localStorage).forEach((key) => {
            const item = localStorage.getItem(key);
            if (item) {
                try {
                    const parsedItem = JSON.parse(item);
                    if (parsedItem && typeof parsedItem === 'object' && 'timestamp' in parsedItem) {
                        const { timestamp } = parsedItem;
                        if (timestamp) {
                            const savedDate = dayJs(timestamp);
                            const differenceInDays = currentDate.diff(savedDate, 'day');
                            if (differenceInDays > expiryDays) {
                                localStorage.removeItem(key);
                            }
                        }
                    }
                } catch (_e) {}
            }
        });
    }, []);
}
