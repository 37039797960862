import styled from '@emotion/styled';
import React from 'react';

import TextArea, { ProjectTextBoxWidth } from 'components/atoms/TextArea/TextArea';

type Props = {
    content?: string;
};

export const ProjectDescription = ({ content }: Props) => {
    const StyledTextArea = styled(TextArea)(({ theme }) => ({
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        width: ProjectTextBoxWidth,
        height: 68,
        maxWidth: 'calc(90%)',
        maxHeight: 'calc(90%)',
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0,
        textAlign: 'left',

        ...(!content && { color: '#6E6E6E91', fontStyle: 'italic' }),
    }));

    return <StyledTextArea readOnly value={content} />;
};
