import htmlContent from '../../readme_oss';

let readmeOss = { __html: htmlContent };

const StaticTermsOfUse = () => {
    return (
        <div>
            <span dangerouslySetInnerHTML={readmeOss}></span>
        </div>
    );
};

export default StaticTermsOfUse;
