import styled from '@emotion/styled';

import StaticPage from 'components/templates/StaticPage';

import useProvisioning from './useProvisioning';

const ProvisioningProgressBar = styled('div')(({ theme }) => ({
    fontSize: 10,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 'normal',
    width: '80%',
    margin: theme.spacing(4, 'auto'),
}));

const ProgressBarValue = styled('div')(({ theme }) => ({
    width: 19,
    height: 14,
    margin: theme.spacing(0, 0, 0, 2),
    color: '#646464',
    display: 'inline',
    textAlign: 'right',
}));

const ProvisioningProgressBarIndicatorDiv = styled('div')(({}) => ({
    margin: 'auto',
    display: 'flex',
}));

const ProvisioningProgressBarBackground = styled('div')(({ theme }) => ({
    overflow: 'auto',
    width: '100%',
    height: 5,
    padding: 0,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#c8f5ff',
    margin: theme.spacing(1, 2, 2, 0),
    display: 'inline',
    borderRadius: 5,
}));

const ProvisioningProgressBarIndicator = styled('div')<{ progress: number }>(({ progress }) => ({
    width: `${progress}%`,
    height: 5,
    borderRadius: 5,
    backgroundColor: '#2387aa',
    transition: 'width 500ms ease-in-out',
}));

function getProgressDescription(progress: number) {
    switch (true) {
        case progress < 25:
            return 'Setting up email templates...';
        case progress < 50:
            return 'Setting up collaboration space...';
        case progress < 75:
            return 'Adding example project...';
        default:
            return 'Uploading design content...';
    }
}

const StaticProvisioning = () => {
    const { progress } = useProvisioning();

    return (
        <StaticPage>
            <h1>Welcome to Connect</h1>

            <p>
                We still need to set up a few more things for you, but it usually takes only a few
                minutes. Please wait.
            </p>

            <ProvisioningProgressBar>
                <ProvisioningProgressBarIndicatorDiv>
                    <ProvisioningProgressBarBackground>
                        <ProvisioningProgressBarIndicator progress={progress} />
                    </ProvisioningProgressBarBackground>
                    <ProgressBarValue>{progress}%</ProgressBarValue>
                </ProvisioningProgressBarIndicatorDiv>
                <span>{getProgressDescription(progress)}</span>
            </ProvisioningProgressBar>

            <span>
                If you have seen this message multiple times, please{' '}
                <a href='https://support.sw.siemens.com/en-US/' target='_blank'>
                    contact support.
                </a>
            </span>
        </StaticPage>
    );
};

export default StaticProvisioning;
