import React from 'react';

import Box from 'components/atoms/Box';
import ProductExcellenceProgramDialog from 'components/templates/ProductExcellenceProgramDialog';
import { Router } from 'providers';

import ConnectProviders from './ConnectProviders';

import './globalStyle.scss';

function App(): React.ReactElement {
    return (
        <ConnectProviders>
            <Box
                css={({ colors }) => ({
                    color: colors.text,
                })}
            >
                <ProductExcellenceProgramDialog />
                <Router />
            </Box>
        </ConnectProviders>
    );
}

export default App;
