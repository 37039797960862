import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Path } from 'types/paths.enum';

const useTranslateRoute = () => {
    const [isTranslatingUrl, setIsTranslatingUrl] = useState(true);

    const params = useLocation();
    const navigate = useNavigate();

    const redirect = (url: string) => {
        navigate(url);
        setIsTranslatingUrl(false);
    };

    // Former link to project, based on hashrouter, has format
    // .../#/project/collaborationSpaceId/projectId
    // Param.hash contains part of this address begins with '#'
    // It cause that prams.hash have to contain four parts and
    // third of them contains projectId, we have to check it
    // during url conversion.

    useEffect(() => {
        if (params.hash.startsWith('#/project')) {
            const parts = params.hash.split('/');
            if (parts.length === 4) {
                const projectId = parts[3] ?? '';
                redirect(`${Path.PROJECTS}/${projectId}`);
            } else {
                redirect(Path.ERROR_404);
            }
        } else {
            setIsTranslatingUrl(false);
        }
    }, []);
    return { isTranslatingUrl };
};

export default useTranslateRoute;
