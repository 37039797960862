import React, { PropsWithChildren } from 'react';

import { useWalkMe } from 'hooks/useWalkMe';
import { User } from 'providers/currentUser/UserDataProvider';
import { createGenericContext } from 'shared/createGenericContext';

const [useWalkMeContext, WalkMeContextProvider] = createGenericContext<{
    initializeWalkMe: (userData: User, isWalkMeEnabled: boolean, isInternal: boolean) => void;
}>();

const WalkMeProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const { initialize } = useWalkMe();

    return (
        <WalkMeContextProvider value={{ initializeWalkMe: initialize }}>
            {children}
        </WalkMeContextProvider>
    );
};

export { useWalkMeContext, WalkMeProvider };
