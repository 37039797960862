import StaticPage from 'components/templates/StaticPage';

const StaticLoggingIn = () => {
    return (
        <StaticPage>
            <h1>Loading Connect...</h1>
        </StaticPage>
    );
};

export default StaticLoggingIn;
