import ErrorPage from 'components/templates/ErrorPage';
import ErrorLabel from 'images/error_generic.svg?react';
import { useAuthContext } from 'providers/authorization/AuthProvider';

const StaticError = () => {
    const { isAuthenticated } = useAuthContext();
    return (
        <ErrorPage showLogout errorLabel={<ErrorLabel />}>
            <h1>This page didn't load Connect correctly.</h1>
            <p>
                <span>Please try to log out</span>
                {isAuthenticated && (
                    <span>first or use the Return Home button to rule out a web glitch</span>
                )}
                <span>.</span>
                <br />
                If you have seen this message multiple times, please contact our support.
            </p>
        </ErrorPage>
    );
};

export default StaticError;
