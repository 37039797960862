import useLocalStorage from 'hooks/useLocalStorage';

const USER_LOGINS_DATA = 'CONNECT_USER_LOGIN_DATA';

type UserLogins = {
    userLogins: number;
    lastChangeDate: string;
};

//TODO: to be removed in sprint 218 - task S-749993
const migrateUserLogins = () => {
    const todayDate = new Date().toISOString().split('T')[0];
    const USER_LOGINS = 'USER_LOGINS';
    const LAST_CHANGE_DATE = 'LAST_CHANGE_DATE';

    let userLogin = window.localStorage.getItem(USER_LOGINS);
    let lastChangeDate = window.localStorage.getItem(LAST_CHANGE_DATE);

    if (userLogin) {
        window.localStorage.removeItem(USER_LOGINS);
        window.localStorage.removeItem(LAST_CHANGE_DATE);

        if (lastChangeDate !== todayDate) {
            return { userLogins: +userLogin + 1, lastChangeDate: todayDate };
        } else {
            return { userLogins: +userLogin, lastChangeDate: lastChangeDate };
        }
    }
};

const useUserLogins = () => {
    const todayDate = new Date().toISOString().split('T')[0];
    const [userLoginsData, setUserLoginsData] = useLocalStorage<UserLogins>(USER_LOGINS_DATA, {
        userLogins: 0,
        lastChangeDate: new Date(0).toISOString().split('T')[0],
    });

    //TODO: to be removed in sprint 218 - task S-749993
    const oldUserLogins = migrateUserLogins();

    if (oldUserLogins) {
        setUserLoginsData({
            userLogins: oldUserLogins.userLogins,
            lastChangeDate: oldUserLogins.lastChangeDate ?? todayDate,
        });
    } else {
        if (userLoginsData.lastChangeDate !== todayDate) {
            setUserLoginsData({
                userLogins: +userLoginsData.userLogins + 1,
                lastChangeDate: todayDate,
            });
        }
    }

    return userLoginsData;
};

export default useUserLogins;
