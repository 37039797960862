import { useMutation } from '@tanstack/react-query';

import { addProjectToRecent } from 'services/api/synchronization/project.service';

const useAddProjectToRecent = () =>
    useMutation(addProjectToRecent, {
        onError: (e) => {
            console.error(e);
        },
    });

export default useAddProjectToRecent;
