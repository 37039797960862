import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
    QueryClientProvider as QueryClientProviderV5,
    QueryClient as QueryClientV5,
} from 'react-query-v5';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
            staleTime: 1000 * 30,
        },
    },
});

const queryClientV5 = new QueryClientV5({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
            staleTime: 1000 * 30,
        },
    },
});

interface IApiProviderProps {
    children: React.ReactElement;
}

function ApiProvider({ children }: IApiProviderProps) {
    return (
        <QueryClientProvider client={queryClient}>
            <QueryClientProviderV5 client={queryClientV5}>
                {children}
                <ReactQueryDevtools initialIsOpen={false} position='top-right' />
            </QueryClientProviderV5>
        </QueryClientProvider>
    );
}

export default ApiProvider;
