import styled from '@emotion/styled';

export const CheckboxRow = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 0, 0, 0),
    color: theme.colors.text,
}));

export const CheckBoxLabel = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    color: theme.colors.text,
}));

export const Paragraph = styled('p')(({ theme }) => ({
    marginTop: theme.spacing(1),
    '& a': {
        display: 'inline',
        marginRight: theme.spacing(6),
        color: theme.colors.actionText,
        fontWeight: 'bolder',
        textDecoration: 'none',
        '&:hover': { color: theme.colors.actionTextHover, cursor: 'pointer' },
    },
    color: theme.colors.text,
}));
