import React, { Ref, forwardRef, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import Delay from 'components/atoms/Delay';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import SideNav from 'components/molecules/SideNav';
import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import { ProjectSideNav } from 'components/organisms/AddUpdateProject/ProjectSideNav';
import { SideNavMode } from 'components/organisms/AddUpdateProject/enums/sideNavMode';
import MainLayout from 'components/templates/MainLayout';
import {
    addNewProjectBeginEvent,
    addNewProjectFinishEvent,
} from 'components/templates/ProjectEditView/events';
import ProjectTiles from 'components/templates/ProjectTiles';
import ProjectsTable from 'components/templates/ProjectsTable';
import {
    ColumnName,
    SortTable,
    TableSortDirection,
} from 'components/templates/ProjectsTable/ProjectsTable';
import { useSettings } from 'hooks/useSettings';
import { OnGridSort, SortFields, SortType } from 'hooks/useSortTiles';
import NoProjectsIcon from 'images/cmdAbortTask24.svg?react';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { useGetProjectsQuery } from 'services/queries/project.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { Path } from 'types/paths.enum';
import { IProjectOpen, Project, OwnerShip as TOwnerShip } from 'types/project';
import { SelectedProjectData } from 'types/selection';

import { AllProjectsPage, AllProjectsWorkspace } from './AllProjects.styles';
import { AllProjectsMenu } from './components/AllProjectsMenu';
import { useAllProjectTabs } from './hooks/useAllProjectsTabs';

const OwnerShip: TOwnerShip[] = [undefined, 'OwnedByMe', 'SharedWithMe', 'SharedByMe'];
const { setProgressBar } = useProgressBar();

interface ISettings {
    current: VerticalMenuViewType.GRID | VerticalMenuViewType.TABLE;
    grid: SortFields;
    table: SortTable;
}

const allProjectSettings = 'allProjectSettings';

const Div = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    return <div ref={ref} style={{ width: '100%', height: '1px' }} />;
});

export type OnTableSort = (sortColumn: { id: ColumnName }, sortOrder: TableSortDirection) => void;

export function AllProjects() {
    const { getSetting, storeSetting } = useSettings();

    const [settings, setSettings] = useState<ISettings>(() => {
        const settings = getSetting(allProjectSettings);
        return settings
            ? JSON.parse(settings)
            : {
                  current: VerticalMenuViewType.GRID,
                  grid: {
                      sortColumn: 'lastModifiedDate',
                      sortOrder: 'descending',
                      type: SortType.LATEST,
                  },
              };
    });

    const [currentFetchingState, setCurrentFetchingState] = useState(false);

    const [selected, setSelected] = useState({} as Project);
    const [sideNavState, setSideNavState] = useState(SideNavMode.closed);
    const [isAddNewProjectInAction, setIsAddNewProjectInAction] = useState(false);

    const { collaborationSpaceId } = useUserDataContext();
    const { AllProjectTabs, selectedTabId } = useAllProjectTabs();
    const navigator = useNavigate();
    const { ref, inView } = useInView();

    const onAddNewProjectBeginEvent = () => setIsAddNewProjectInAction(true);
    const onAddNewProjectFinishEvent = () => setIsAddNewProjectInAction(false);

    useEffect(() => {
        document.addEventListener(addNewProjectBeginEvent, onAddNewProjectBeginEvent);
        document.addEventListener(addNewProjectFinishEvent, onAddNewProjectFinishEvent);
        return () => {
            document.removeEventListener(addNewProjectBeginEvent, onAddNewProjectBeginEvent);
            document.removeEventListener(addNewProjectFinishEvent, onAddNewProjectFinishEvent);
        };
    }, []);

    useEffect(() => {
        storeSetting(allProjectSettings, JSON.stringify(settings));
    }, [settings]);

    React.useEffect(() => {
        setSelected({} as Project);
    }, [selectedTabId]);

    const setViewType = (viewType: VerticalMenuViewType) => {
        setSettings((prevState) => ({
            ...prevState,
            current:
                viewType === VerticalMenuViewType.TABLE
                    ? VerticalMenuViewType.TABLE
                    : VerticalMenuViewType.GRID,
        }));
    };

    const onSelected = (selectedProjects: Array<SelectedProjectData>) => {
        const currentSelection = selectedProjects?.[0]?.original ?? {};
        setSelected(currentSelection);
    };

    const onOpen = (project: IProjectOpen) => {
        const lastRevision = project.latestRevision ? '/' + project.latestRevision : '';

        settings.current === VerticalMenuViewType.GRID
            ? triggerAnalyticsClickEvent(
                  analyticsConsts.Actions.openProject,
                  analyticsConsts.Categories.navigation,
                  analyticsConsts.Sources.projectTile
              )
            : triggerAnalyticsClickEvent(
                  analyticsConsts.Actions.openProject,
                  analyticsConsts.Categories.navigation,
                  analyticsConsts.Sources.projectList
              );

        navigator(`${Path.PROJECTS}/${project.projectId + lastRevision}`);
    };

    const onGridSorted: OnGridSort = (sort) => {
        setSettings((prevState) => ({ ...prevState, grid: sort }));
    };

    const onTableSorted: OnTableSort = (column, sortOrder) => {
        let sorting: SortTable;
        if (sortOrder) {
            sorting = {
                sortColumn: column.id,
                sortOrder: sortOrder,
            };
        }
        setSettings((prevState) => ({ ...prevState, table: sorting }));
    };

    const {
        data,
        isLoading: isLoadingProjects,
        isFetching: isFetchingProjects,
        fetchNextPage,
        hasNextPage,
    } = useGetProjectsQuery({
        collaborationSpaceId,
        ownerShipFilter: OwnerShip[selectedTabId],
        sortColumn: settings[settings.current]?.sortColumn,
        sortOrder: settings[settings.current]?.sortOrder,
    });

    useEffect(() => {
        hasNextPage && inView && fetchNextPage();
    }, [inView, data?.pages]);

    const parsedData = useMemo(
        () =>
            data?.pages
                .map((x) => x.content)
                .flat()
                .map((x: Project) => {
                    return { ...x, id: x.projectId };
                }) ?? [],
        [data]
    );

    useEffect(() => {
        if (!isLoadingProjects) {
            if (currentFetchingState !== isFetchingProjects) {
                setProgressBar(isFetchingProjects);
                setCurrentFetchingState(isFetchingProjects);
            }

            if (!isFetchingProjects) {
                setProgressBar(false);
            }
        }
    }, [isFetchingProjects, isLoadingProjects]);

    useEffect(() => {
        setSelected((previous) =>
            previous?.id
                ? (parsedData?.find((x) => x.projectId === previous.projectId) ?? ({} as Project))
                : ({} as Project)
        );
    }, [parsedData]);

    const tableDataPagination = {
        hasMore: hasNextPage,
        component: <Div ref={ref} />,
    };

    const mapToSimpleProject = (project: Project) => {
        return {
            projectId: project.projectId + '/' + project.collaborationSpaceId,
            legacyCollaborationSpaceId: project.collaborationSpaceId,
            legacyProjectId: project.projectId,
            name: project.name,
            description: project.description,
            scanInfo: project.scanInfo,
            thumbnailUri: project.thumbnailUri,
        };
    };

    return (
        <MainLayout>
            <AllProjectsPage>
                {sideNavState !== SideNavMode.closed ? (
                    <SideNav
                        title={
                            sideNavState === SideNavMode.createNewProject
                                ? 'Create new project'
                                : 'Edit project'
                        }
                        children={
                            <ProjectSideNav
                                collaborationSpaceId={collaborationSpaceId}
                                selected={mapToSimpleProject(selected)}
                                sideNavState={sideNavState}
                                setSideNavState={setSideNavState}
                            />
                        }
                        open={
                            sideNavState === SideNavMode.createNewProject ||
                            sideNavState === SideNavMode.editProject
                        }
                        setOpen={(): void => {
                            setSideNavState(SideNavMode.closed);
                        }}
                        modal={false}
                    ></SideNav>
                ) : null}
                <AllProjectTabs />
                <AllProjectsMenu
                    viewType={settings.current}
                    viewTypeChange={setViewType}
                    sideNavStateChange={setSideNavState}
                    currentSelection={selected}
                    hideModifyButtons={!(isLoadingProjects || isAddNewProjectInAction)}
                    sort={{
                        onSort: onGridSorted,
                        visible: settings.current === VerticalMenuViewType.GRID,
                        sortType: settings.grid.sortType,
                    }}
                />
                {isAddNewProjectInAction && (
                    <Delay
                        css={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <ProgressSpinner label={LoadingStateLabel.PROJECT_CREATING} />
                    </Delay>
                )}
                {isLoadingProjects && !isAddNewProjectInAction && !data && (
                    <Delay
                        css={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <ProgressSpinner label={LoadingStateLabel.PROJECTS} />
                    </Delay>
                )}
                <AllProjectsWorkspace>
                    {!(isLoadingProjects || isAddNewProjectInAction) &&
                    data &&
                    parsedData.length !== 0 ? (
                        settings.current === VerticalMenuViewType.GRID ? (
                            <ProjectTiles
                                loadingComponent={hasNextPage ? <Div ref={ref} /> : null}
                                data={parsedData}
                                onSelected={onSelected}
                                onOpen={onOpen}
                                currentSelection={selected}
                            />
                        ) : (
                            <ProjectsTable
                                data={parsedData}
                                pagination={tableDataPagination}
                                onOpen={onOpen}
                                onSorted={onTableSorted}
                                onSelected={onSelected}
                                currentSelection={selected}
                                initialSortSettings={{
                                    sortedColumnName: settings.table?.sortColumn,
                                    sortDirection: settings.table?.sortOrder,
                                }}
                            />
                        )
                    ) : null}
                    {!(isLoadingProjects || isAddNewProjectInAction) &&
                    data &&
                    parsedData.length === 0 ? (
                        <CcdEmptyState
                            id={'AllProjectsEmptyState'}
                            message='There are no projects here yet'
                            subMessage='Create a new project first or have one shared with you'
                            icon={NoProjectsIcon}
                        />
                    ) : null}
                </AllProjectsWorkspace>
            </AllProjectsPage>
        </MainLayout>
    );
}

export default AllProjects;

export class View {}
