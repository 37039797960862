import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import Tooltip from 'components/atoms/Tooltip';
import EditIcon from 'images/cmdEdit16.svg';

import { AlignCenter } from 'components/atoms/Flex/Flex';
import {
    BreadCrumbBox,
    BreadCrumbItem,
    BreadCrumbItems,
    BreadCrumbSeparator,
    BreadCrumbTrigger,
    IconImage,
} from './BreadCrumb.styles';

interface BreadCrumbInterface {
    projectName: string;
    onProject: () => void;
    onEdit?: () => void;
}

export const BreadCrumb = ({ projectName, onProject, onEdit }: BreadCrumbInterface) => {
    return (
        <BreadCrumbBox>
            <NavigationMenu.Root>
                <BreadCrumbItems>
                    <NavigationMenu.Item>
                        <AlignCenter>
                            <BreadCrumbTrigger onClick={onProject}>Projects</BreadCrumbTrigger>
                            <BreadCrumbSeparator />
                        </AlignCenter>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item>
                        <BreadCrumbItem>{projectName}</BreadCrumbItem>
                    </NavigationMenu.Item>
                    {onEdit ? (
                        <NavigationMenu.Item>
                            <Tooltip title={'Edit project'} arrow enterDelay={500} placement='top'>
                                <IconImage
                                    layout='fixed'
                                    width={16}
                                    height={16}
                                    src={EditIcon}
                                    onClick={onEdit}
                                />
                            </Tooltip>
                        </NavigationMenu.Item>
                    ) : null}
                </BreadCrumbItems>
            </NavigationMenu.Root>
        </BreadCrumbBox>
    );
};
