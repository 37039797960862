import { useEffect } from 'react';

import useCookieLayer from 'hooks/cookies/useCookieLayer';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';

const CookieLayer = () => {
    const { hasInteracted, showFirstLayer } = useCookieLayer();
    const { data } = useFeatureFlagsQuery();

    useEffect(() => {
        if (!hasInteracted && data?.Frontend_Cookies) {
            showFirstLayer();
        }
    }, [hasInteracted, data]);

    return null;
};

export default CookieLayer;
