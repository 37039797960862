import { PropsWithChildren } from 'react';

import { SiemensAnalytics } from 'hooks/analytics/SiemensAnalitics';
import CookieLayer from 'hooks/cookies/CookieLayer';
import { SubscriptionManagerProvider } from 'providers';
import { UserDataProvider } from 'providers/currentUser/UserDataProvider';

export function AuthenticatedProviders({ children }: PropsWithChildren): JSX.Element {
    return (
        <UserDataProvider>
            <CookieLayer />
            <SiemensAnalytics />
            <SubscriptionManagerProvider>{children}</SubscriptionManagerProvider>
        </UserDataProvider>
    );
}

export default AuthenticatedProviders;
