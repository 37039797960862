import styled from '@emotion/styled';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Image, ImageProps } from '@unpic/react';

import { AlignCenter } from 'components/atoms/Flex/Flex';
import ArrowIcon from 'images/breadCrumbArrow.svg?react';

export const BreadCrumbBox = styled(AlignCenter)({ fontSize: 12 });

export const IconImage = styled(Image)((_props: ImageProps) => ({ theme }) => ({
    width: 16,
    height: 16,
    backgroundColor: theme.colors.background,
    '&:hover': {
        cursor: 'pointer',
    },
}));

export const BreadCrumbItems = styled(NavigationMenu.List)({
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    listStyle: 'none',
    lineHeight: '16px',
    margin: 0,
});

export const BreadCrumbTrigger = styled('div')(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.actionText,
    width: 48,

    '&:hover': {
        color: theme.colors.actionTextHover,
        cursor: 'pointer',
    },
}));

export const BreadCrumbSeparator = styled(ArrowIcon)(({ theme }) => ({
    filter: theme.colors.filterArrowIcon,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: 16,
    width: 16,
}));

export const BreadCrumbItem = styled('div')(({ theme }) => ({
    fontStyle: 'normal',
    color: theme.colors.text,
    fontWeight: 400,
    fontSize: 12,
    marginRight: theme.spacing(2),

    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
}));
