import { Box } from '@mui/material';
import { Buffer } from 'buffer';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useProgressBar } from 'components/atoms/ProgressBar';
import MainLayout from 'components/templates/MainLayout';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useGetAcc } from 'services/api/acc.service';
import { Path } from 'types/paths.enum';

const PDFViewer = () => {
    const { collaborationSpaceId } = useUserDataContext();
    const { projectId, revisionId, revisionUrn, pdfType } = useParams();
    const { data, isLoading } = useGetAcc();
    const decodedRevisionUrn = useMemo(() => {
        return revisionUrn ? Buffer.from(revisionUrn, 'base64').toString() : '';
    }, [revisionUrn]);
    const decodedPdfType = useMemo(() => {
        return pdfType ? Buffer.from(pdfType, 'base64').toString() : '';
    }, [revisionUrn]);
    const navigator = useNavigate();
    const { setProgressBar } = useProgressBar();

    useEffect(() => {
        setProgressBar(true);
        return () => setProgressBar(false);
    }, []);

    useEffect(() => {
        const accViewerClose = () => {
            navigator(`${Path.PROJECTS}/${projectId}/${revisionId}`);
        };

        const accViewerLoaded = () => setProgressBar(false);

        window.addEventListener('des-collab-pdf-viewer.close', accViewerClose);
        window.addEventListener('des-collab-pdf-viewer.dependency-load-end', accViewerLoaded);

        return () => {
            window.removeEventListener('des-collab-pdf-viewer.close', accViewerClose);
            window.removeEventListener(
                'des-collab-pdf-viewer.dependency-load-end',
                accViewerLoaded
            );
        };
    }, []);

    const PDFViewerElement = () => {
        return React.createElement('des-collab-pdf-viewer', {
            'acc-session-id': data,
            'collaborationspace-id': collaborationSpaceId,
            'container-type': 'project',
            'container-id': projectId,
            'revision-urn': decodedRevisionUrn,
            // prettier-ignore
            alias: decodedPdfType,
        });
    };

    return (
        <MainLayout stretchViewer>
            <Box css={{ width: '100%', height: '100%' }}>
                {!isLoading && data ? <PDFViewerElement /> : null}
            </Box>
        </MainLayout>
    );
};
export default PDFViewer;
