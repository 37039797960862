import ErrorPage from 'components/templates/ErrorPage';
import ErrorLabel from 'images/error_404.svg?react';

const StaticError404 = () => {
    return (
        <ErrorPage errorLabel={<ErrorLabel />}>
            <h1>Sorry, the page you are looking for does not exist.</h1>
            <p>If you think something is broken, report a problem by clicking Help below.</p>
        </ErrorPage>
    );
};

export default StaticError404;
