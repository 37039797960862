import styled from '@emotion/styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import { DialogButton } from 'components/atoms/Button';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import Dialog from 'components/molecules/Dialog';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { lockProject } from 'services/api/project.service';
import { PROJECTS_QUERY } from 'services/queries/project.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { Project } from 'types/project';

const DialogContent = styled('span')({
    overflowWrap: 'break-word',
    fontSize: 12,
});

export const useLockProject = (project: Project) => {
    const [inProgress, setInProgress] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();

    const toastCommonParams = {
        autoClose: true,
        autoCloseTime: 3000,
        onClose(): void {},
        title: '',
    };

    const { mutate } = useMutation(lockProject, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.lockProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectList
            );
            showToast({
                ...toastCommonParams,
                type: 'info',
                messages: ['Project locked successfully.'],
            });
        },
        onError: (
            error: AxiosError<{
                lockedBy: 'string';
                isCallerUserLock: boolean;
            }>
        ) => {
            queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            if (
                error.response &&
                error.response.status === 409 &&
                !error.response.data.isCallerUserLock
            ) {
                showToast({
                    ...toastCommonParams,
                    type: 'error',
                    messages: [`Project already locked by ${error.response.data.lockedBy}.`],
                });
            } else {
                showToast({
                    ...toastCommonParams,
                    type: 'error',
                    messages: ['Cannot lock project.'],
                });
            }
        },
        onSettled: () => {
            setProgressBar(false);
            setInProgress(false);
            setShowDialog(false);
        },
    });

    const DialogComponent = () => (
        <Dialog
            open={showDialog}
            closeButton={!inProgress}
            setOpen={inProgress ? () => {} : setShowDialog}
            title={'Lock project'}
            contentCssOverride={{ maxWidth: '420px' }}
            buttons={
                !inProgress ? (
                    <>
                        <DialogButton
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => {
                                setShowDialog(false);
                                setInProgress(false);
                            }}
                            data-testid={'lockProjectCancelButton'}
                        >
                            Cancel
                        </DialogButton>
                        <DialogButton
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                setProgressBar(true);
                                setInProgress(true);
                                mutate(project);
                            }}
                            data-testid={'lockProjectDeleteButton'}
                        >
                            Lock
                        </DialogButton>
                    </>
                ) : null
            }
        >
            <DialogContent>
                {!inProgress ? (
                    <span>
                        Do you want to lock: <b>{project.name}</b>?{' '}
                    </span>
                ) : (
                    <Box>
                        <ProgressSpinner label={LoadingStateLabel.LOCKING} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

    return {
        openLockProjectDialog: () => setShowDialog(true),
        lockProjectDialog: <DialogComponent />,
        inProgress,
    };
};
