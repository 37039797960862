import StaticLoggingIn from 'pages/StaticLoggingIn';
import { redirectToLogin, useAuthContext } from 'providers/authorization/AuthProvider';

const LoginRoute = () => {
    const { isAuthenticated } = useAuthContext();

    if (isAuthenticated) {
        redirectToLogin();
    }

    return <StaticLoggingIn />;
};

export default LoginRoute;
