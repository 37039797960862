import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const AllProjectsPage = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

export const AllProjectsWorkspace = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    overflow: 'auto',
}));
