import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useProgressBar } from 'components/atoms/ProgressBar';
import { inCacheProjectStatus } from 'hooks/useXccService';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';
import { useGetProjectQuery } from 'services/queries/project.query';
import { Path } from 'types/paths.enum';

import XccSimpleViewer from './XccSimpleViewer';

const XccViewer = () => {
    const { collaborationSpaceId } = useUserDataContext();
    const [url, setUrl] = useState('');
    const [isOpenedFromUrl, setIsOpenedFromUrl] = useState(!collaborationSpaceId);
    const { projectId, revisionId, designType = 'schematic', mode } = useParams();
    const navigate = useNavigate();
    const { setProgressBar } = useProgressBar();
    const [projectChecked, setProjectChecked] = useState(false);
    const xccContext = useContext(XccInfrastructureProviderContext);
    const project = {
        collaborationSpaceId,
        projectId,
        revision: revisionId,
        designType,
        mode,
    };

    const { data: currentProjectData } = useGetProjectQuery({
        collaborationSpaceId,
        projectId: projectId,
    });

    const checkIfViewerIsReady = (availableDesignType: Array<string>) => {
        let designType = '';
        if (availableDesignType.includes('Schematic')) {
            designType += 'Schematic';
        }
        if (availableDesignType.includes('Layout')) {
            if (designType !== '') {
                designType += ',';
            }
            designType += 'Layout';
        }
        return designType;
    };

    const closeViewer = () => {
        navigate(`${Path.PROJECTS}/${projectId}/${revisionId}`);
    };

    useEffect(() => {
        if (project.collaborationSpaceId) {
            if (isOpenedFromUrl) {
                const check = async () => {
                    if (
                        project.collaborationSpaceId &&
                        project.projectId &&
                        project.revision &&
                        designType &&
                        mode
                    ) {
                        const cacheState = await xccContext.isProjectInCache(project);
                        if (
                            cacheState.status === inCacheProjectStatus &&
                            cacheState.designType.includes(mode)
                        ) {
                            let projectToOpen = {
                                ...project,
                                designType: checkIfViewerIsReady(cacheState.designType),
                            };

                            setUrl(xccContext.prepareUrl(projectToOpen));
                            setIsOpenedFromUrl(false);
                        } else {
                            navigate(Path.ERROR_403);
                        }
                    }
                };

                if (!projectChecked) {
                    setProjectChecked(true);
                    check();
                }
            } else {
                setUrl(xccContext.prepareUrl(project));
            }
        }
    }, [collaborationSpaceId]);

    useEffect(() => {
        setProgressBar(true);
        return () => {
            setProgressBar(false);
        };
    }, []);

    return (
        <XccSimpleViewer
            url={url}
            onClose={closeViewer}
            loading={isOpenedFromUrl}
            projectName={currentProjectData?.name}
        />
    );
};

export default XccViewer;
