import useChangeView from 'components/_legacy/hooks/useChangeView';
import VerticalMenu, { TButtons } from 'components/molecules/VerticalMenu';

export interface RecentProjectMenuInterface {
    viewType: string;
    viewTypeChange: (viewType: string) => void;
}

export function RecentProjectsMenu({ viewType, viewTypeChange }: RecentProjectMenuInterface) {
    const { changeView } = useChangeView(viewTypeChange, viewType);

    const buttons: TButtons = [changeView];

    return <VerticalMenu buttons={buttons} />;
}
