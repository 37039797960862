import { useState } from 'react';

import IntercomWidget from 'components/externals/Intercom/intercom.widget';
import appConfig from 'constants/appConfig';
import { User } from 'providers/currentUser/UserDataProvider';

export const useIntercom = () => {
    const [initialized, setInitialized] = useState<boolean>(false);

    return (userData: User) => {
        if (initialized) {
            return;
        }
        window.intercomSettings = {
            app_id: appConfig.INTERCOM_APP_ID,
            email: userData.email,
            name: userData.name,
            user_id: userData.userId ?? '',
            vertical_padding: 75,
        };

        IntercomWidget(window.intercomSettings.app_id);
        setInitialized(true);
    };
};
