import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import { DialogButton } from 'components/atoms/Button';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import Dialog from 'components/molecules/Dialog';
import { useProjectPermission } from 'providers/permissions';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { unlockProject } from 'services/api/project.service';
import { PROJECTS_QUERY } from 'services/queries/project.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { ProjectPermissions } from 'types/permissions';
import { Project } from 'types/project';

const DialogContent = styled('span')({
    overflowWrap: 'break-word',
    fontSize: 12,
});

export const useUnlockProject = (project: Project) => {
    const [inProgress, setInProgress] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showForceUnlockDialog, setShowForceUnlockDialog] = useState(false);
    const [showUnlockConfirmationDialog, setShowUnlockConfirmationDialog] = useState(false);
    const [lockInformation, setLockInformation] = useState('');
    const { hasProjectPermission } = useProjectPermission(
        project.collaborationSpaceId,
        project.projectId
    );
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();
    const canForceUnlock = hasProjectPermission(ProjectPermissions.ProjectForceUnlock);
    const toastCommonParams = {
        autoClose: true,
        autoCloseTime: 3000,
        onClose(): void {},
        title: '',
    };

    interface LockData extends AxiosError {
        isCallerUserLock: boolean;
        lockedBy: string;
    }

    const setInitialState = () => {
        setShowForceUnlockDialog(false);
        setShowDialog(false);
        setShowUnlockConfirmationDialog(false);
    };

    const processErrorResponse = (status: AxiosError) => {
        const lockData = status.response?.data as LockData;
        if (lockData && status.response?.status == 409) {
            if (!lockData.isCallerUserLock) {
                setLockInformation(lockData.lockedBy);
                setShowForceUnlockDialog(true);
                setShowDialog(false);
            }
        }
    };

    const { mutate } = useMutation(unlockProject, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.unlockProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectList
            );
            showToast({
                ...toastCommonParams,
                type: 'info',
                messages: ['Project unlocked successfully.'],
            });
            setInitialState();
        },
        onError: (e: AxiosError) => {
            processErrorResponse(e);
        },
        onSettled: () => {
            setInProgress(false);
            setProgressBar(false);
        },
    });

    const DialogComponent = () => (
        <Dialog
            open={showDialog}
            closeButton={!inProgress}
            setOpen={inProgress ? () => {} : setShowDialog}
            title={'Unlock project'}
            contentCssOverride={{ maxWidth: '420px' }}
            buttons={
                !inProgress ? (
                    <>
                        <DialogButton
                            variant={'outlined'}
                            color='primary'
                            onClick={() => {
                                setInitialState();
                                setInProgress(false);
                            }}
                            data-testid={'lockProjectCancelButton'}
                        >
                            Cancel
                        </DialogButton>
                        <DialogButton
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                setProgressBar(true);
                                setInProgress(true);
                                mutate(project);
                            }}
                            data-testid={'lockProjectDeleteButton'}
                        >
                            Unlock
                        </DialogButton>
                    </>
                ) : null
            }
        >
            <DialogContent>
                {!inProgress ? (
                    <span>
                        Do you want to unlock: <b>{project.name}</b>?
                    </span>
                ) : (
                    <Box>
                        <ProgressSpinner label={LoadingStateLabel.UNLOCKING} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

    const LockedDialogComponent = () => (
        <Dialog
            open={showUnlockConfirmationDialog}
            closeButton={!inProgress}
            setOpen={inProgress ? () => {} : setShowUnlockConfirmationDialog}
            title={'Force unlock project'}
            buttons={
                !inProgress ? (
                    <>
                        <DialogButton
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => {
                                setInitialState();
                                setInProgress(false);
                            }}
                            id={'lockProjectCancelButton'}
                        >
                            Cancel
                        </DialogButton>
                        <DialogButton
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                setProgressBar(true);
                                setInProgress(true);
                                mutate({ ...project, force: true });
                            }}
                            id={'lockProjectDeleteButton'}
                        >
                            Force unlock
                        </DialogButton>
                    </>
                ) : null
            }
        >
            <DialogContent>
                {!inProgress ? (
                    <span>
                        Are you sure you want to <b>Force unlock this project?</b>
                    </span>
                ) : (
                    <Box>
                        <ProgressSpinner label={LoadingStateLabel.UNLOCKING} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

    const ForceDialogComponent = () => (
        <Dialog
            open={showForceUnlockDialog}
            closeButton={!inProgress}
            setOpen={setShowForceUnlockDialog}
            title={'Force unlock project'}
            buttons={
                !inProgress ? (
                    <>
                        <DialogButton
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => {
                                setInitialState();
                                setInProgress(false);
                            }}
                            id={'lockProjectCancelButton'}
                        >
                            Cancel
                        </DialogButton>
                        <DialogButton
                            disabled={!canForceUnlock}
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                setShowForceUnlockDialog(false);
                                setShowUnlockConfirmationDialog(true);
                                setInProgress(false);
                            }}
                            id={'lockProjectDeleteButton'}
                        >
                            Force unlock
                        </DialogButton>
                    </>
                ) : null
            }
        >
            {!inProgress ? (
                !canForceUnlock ? (
                    <DialogContent>
                        Project locked by <b>{lockInformation}</b> and cannot be unlocked.
                    </DialogContent>
                ) : (
                    <DialogContent>
                        Project locked by <b>{lockInformation}</b>
                    </DialogContent>
                )
            ) : (
                <DialogContent>
                    <Box>
                        <ProgressSpinner label={LoadingStateLabel.FORCE_UNLOCKING} />
                    </Box>
                </DialogContent>
            )}
        </Dialog>
    );
    return {
        openUnlockProjectDialog: () => setShowDialog(true),

        unlockProjectDialog: <DialogComponent />,
        forceProjectDialog: <ForceDialogComponent />,
        lockedProjectDialog: <LockedDialogComponent />,
        inProgress,
    };
};
